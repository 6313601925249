<template>
    <div class="min-h-screen bg-gray-100 p-4">
      <div class="text-xl font-bold text-gray-900 mb-6">
        Order #{{ order.id }} Details
      </div>
  
      <!-- Order Details -->
      <div class="bg-white rounded-lg shadow p-5">
        <p><strong>Order ID:</strong> {{ order.id }}</p>
        <p>
          <strong>Customer Email:</strong> 
          <a :href="customerUrl" target="_blank" class="text-blue-600 hover:underline">{{ order.email }}</a>
        </p>
        <p>
          <strong>Stripe Charge:</strong> 
          <a :href="chargeUrl" target="_blank" class="text-blue-600 hover:underline">{{ order.stripe_charge_id }}</a>
        </p>
        <p><strong>Status:</strong> {{ order.status }}</p>
        <p><strong>Total Amount:</strong> ${{ order.amount }}</p>
        <p><strong>Ordered On:</strong> {{ new Date(order.created_at).toLocaleDateString() }}</p>
  
        <!-- Order Items -->
        <div class="mt-4">
          <h2 class="font-semibold text-lg mb-2">Items</h2>
          <ul>
            <li v-for="item in order.items" :key="item.name" class="border-b py-2">
              {{ item.quantity }} x {{ item.name }} - ${{ (item.price / 100).toFixed(2) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import axios from 'axios';
  
  const route = useRoute();
  const order = ref({});
  
  // Fetch order details when the component is mounted
  onMounted(async () => {
    try {
      const response = await axios.get(`http://localhost/api/admin/orders/${route.params.id}`);
      order.value = response.data;
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  });
  
  // Computed properties for Stripe URLs
  const customerUrl = computed(() => {
    if (order.value.stripe_customer_id) {
      return `https://dashboard.stripe.com/customers/${order.value.stripe_customer_id}`;
    }
    return '#';
  });
  
  const chargeUrl = computed(() => {
    if (order.value.stripe_charge_id) {
      return `https://dashboard.stripe.com/payments/${order.value.stripe_charge_id}`;
    }
    return '#';
  });
  </script>
  