<template>
    <div class="container mx-auto p-4">
      <h1 class="text-3xl font-bold mb-6 text-center">Shop Our Products</h1>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <div v-for="product in products" :key="product.id" class="border rounded-lg p-4 shadow-lg">
          <img :src="product.image" :alt="product.name" class="w-full h-40 object-cover mb-4">
          <h2 class="text-xl font-semibold mb-2">{{ product.name }}</h2>
          <p class="text-gray-700 mb-2">{{ product.description }}</p>
          <p class="text-green-600 font-bold mb-4">${{ (product.price / 100).toFixed(2) }}</p>
          <button @click="addToCart(product)" class="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import cart from '../stores/cartStore'; // Import the reactive cart store
  
  export default {
    data() {
      return {
        products: [
          {
            id: 1,
            name: 'Personalized Christmas Ornament',
            description: 'A beautiful ornament that can be personalized with a QR code to store your holiday memories.',
            price: 1999, // Price in cents for Stripe
            image: 'https://via.placeholder.com/150', // Replace with your product image URL
          },
          {
            id: 2,
            name: 'Special Edition Ornament',
            description: 'Limited edition ornament with unique designs.',
            price: 2499, 
            image: 'https://via.placeholder.com/150',
          },
          // Add more products as needed
        ],
      };
    },
    methods: {
      addToCart(product) {
        // Use the cartStore to add the product to the cart
        cart.addItem(product);
        // Optional feedback to the user
        // alert(`${product.name} has been added to your cart!`);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add custom styles for the shop page if needed */
  </style>
  