<template>
    <div class="container mx-auto p-4">
      <h1 class="text-3xl font-bold mb-6 text-center">Your Cart</h1>
  
      <!-- Check if cart is empty -->
      <div v-if="cart.items.length === 0" class="text-center">
        <p>Your cart is empty.</p>
        <router-link to="/shop" class="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
          Continue Shopping
        </router-link>
      </div>
  
      <!-- Cart Items -->
      <div v-else>
        <div class="grid grid-cols-1 gap-6">
          <div v-for="item in cart.items" :key="item.id" class="flex items-center border-b py-4">
            <img :src="item.image" :alt="item.name" class="w-24 h-24 object-cover">
            <div class="flex-1 ml-4">
              <h2 class="text-xl font-semibold">{{ item.name }}</h2>
              <p class="text-gray-700">{{ item.description }}</p>
              <div class="mt-2 flex items-center">
                <label for="quantity" class="mr-2">Quantity:</label>
                <input 
                  type="number" 
                  v-model.number="item.quantity" 
                  @change="updateQuantity(item)" 
                  class="w-16 border text-center"
                  min="1"
                >
              </div>
            </div>
            <div class="ml-auto text-right">
              <p class="text-lg">${{ (item.price / 100).toFixed(2) }}</p>
              <p class="text-lg font-bold">${{ ((item.price * item.quantity) / 100).toFixed(2) }}</p>
              <button @click="removeItem(item.id)" class="text-red-600 hover:text-red-800 mt-2">
                Remove
              </button>
            </div>
          </div>
        </div>
  
        <!-- Cart Total and Checkout -->
        <div class="mt-6 text-right">
          <h2 class="text-2xl font-bold">Total: ${{ cartTotal }}</h2>
          <button @click="checkout" class="bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-700 mt-4">
            Checkout
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { computed } from "vue";
  import cart from '../stores/cartStore'; // Import the reactive cart store
  import { loadStripe } from '@stripe/stripe-js';
  import { post } from "../api/axoisInstance";
  
  export default {
    setup() {
      const cartTotal = computed(() => {
        return (cart.items.reduce((total, item) => total + item.price * item.quantity, 0) / 100).toFixed(2);
      });
  
      function updateQuantity(item) {
        if (item.quantity < 1) {
          item.quantity = 1;
        }
        cart.saveCart();
      }
  
      function removeItem(productId) {
        cart.removeItem(productId);
      }
  
      async function checkout() {
        try {
          const stripe = await loadStripe('pk_test_51PmowbGtfLmXRA45PFhG837sIRyl2MGcRzEDBJ3xBstBSWEtGGtkfp388J5PTeAUI8sfWRJ4bCp30qG1g00UpYVE00LAgJkq0o'); // Replace with your Stripe public key
  
          const items = cart.items.map(item => ({
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            image: item.image,
          }));
  
          const response = await post("/create-checkout-session", { items });

          console.log(response.data.id);
  
          await stripe.redirectToCheckout({ sessionId: response.data.id });
        } catch (error) {
          console.error('Error during checkout:', error);
          alert('Something went wrong. Please try again.');
        }
      }
  
      return { cart, cartTotal, updateQuantity, removeItem, checkout };
    }
  };
  </script>
  
  <style scoped>
  /* Add custom styles for the cart page if needed */
  </style>
  