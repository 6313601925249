<template>
  <div class="flex flex-col min-h-screen justify-center items-center">
    <div
      class="w-full max-w-md px-4 py-10 bg-white shadow-md rounded-lg md:max-w-xl"
    >
      <h1 class="text-2xl font-bold">Sign Up Page</h1>
      <form @submit.prevent="submitForm" class="space-y-6">
        <!-- First Name -->
        <div>
          <label for="firstName" class="block text-sm font-medium text-gray-700"
            >First Name</label
          >
          <input
            type="text"
            id="firstName"
            v-model="firstName"
            @input="validateName('firstName')"
            required
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <!-- Last Name -->
        <div>
          <label for="lastName" class="block text-sm font-medium text-gray-700"
            >Last Name</label
          >
          <input
            type="text"
            id="lastName"
            v-model="lastName"
            @input="validateName('lastName')"
            required
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <!-- Email -->
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700"
            >Email</label
          >
          <input
            type="email"
            id="email"
            v-model="email"
            required
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <!-- Password -->
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700"
            >Password</label
          >
          <input
            type="password"
            id="password"
            v-model="password"
            required
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
          <p class="text-xs mt-1 text-gray-600">
            Password must contain at least 8 characters, including uppercase,
            lowercase, and numbers.
          </p>
        </div>
        <!-- Confirm Password -->
        <div>
          <label
            for="confirmPassword"
            class="block text-sm font-medium text-gray-700"
            >Confirm Password</label
          >
          <input
            type="password"
            id="confirmPassword"
            v-model="confirmPassword"
            required
            :class="{
              'border-red-500': password !== confirmPassword && confirmPassword,
            }"
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
          <p
            v-if="password !== confirmPassword && confirmPassword"
            class="text-xs mt-1 text-red-500"
          >
            Passwords do not match!
          </p>
        </div>
        <!-- Zip Code -->
        <div>
          <label for="zipCode" class="block text-sm font-medium text-gray-700"
            >Zip Code</label
          >
          <input
            type="text"
            id="zipCode"
            v-model="zipCode"
            required
            pattern="\d*"
            @input="validateZip"
            minlength="5"
            maxlength="5"
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <!-- Submit Button -->
        <button
          type="submit"
          class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Sign Up
        </button>
      </form>
      <!-- Link to Login Page -->
      <p class="text-sm text-center mt-4">
        Already have an account?
        <router-link to="/login" class="text-blue-500 hover:text-blue-700"
          >Login</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { post } from "@/api/axoisInstance";

export default {
  name: "SignupView",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      zipCode: "",
    };
  },
  methods: {
    async register() {
      post("/register", {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword,
        zip_code: this.zipCode,
      })
        .then((response) => {
          console.log("Registration successful:", response.data);
          this.$router.push("/login");
        })
        .catch((error) => {
          console.error("Registration failed:", error.response.data);
        });
    },
    submitForm() {
      if (this.password !== this.confirmPassword) {
        alert("Passwords do not match!");
        return;
      }
      this.register();
    },
    validateName(field) {
      this[field] = this[field].replace(/[^a-zA-Z]/g, "");
    },
    validateZip() {
      this.zipCode = this.zipCode.replace(/[^0-9]/g, "");
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .max-w-md {
    /* Adjusted max width for larger screens */
    max-width: 36rem; /* Adjust the width as needed */
  }
}
</style>
