<template>
  <div class="min-h-screen bg-gray-100 p-4">
    <div class="text-2xl font-bold text-gray-900 mb-6">
      Admin Dashboard
    </div>

    <!-- Summary Section -->
    <div class="grid gap-4 mb-6">
      <div class="bg-white rounded-lg shadow p-5">
        <h2 class="font-semibold text-lg">Summary</h2>
        <p class="text-gray-800">Total Orders: {{ totalOrders }}</p>
        <p class="text-gray-800">Total Revenue: ${{ totalRevenue }}</p>
        <p class="text-gray-800">Pending Orders: {{ pendingOrders }}</p>
      </div>
    </div>

    <!-- Filters Section -->
    <div class="bg-white rounded-lg shadow p-5 mb-6">
      <h2 class="font-semibold text-lg mb-4">Filters</h2>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label class="block text-gray-700">Order Status</label>
          <select v-model="selectedStatus" @change="applyFilters" class="w-full mt-1 p-2 border rounded">
            <option value="">All</option>
            <option value="paid">Paid</option>
            <option value="pending">Pending</option>
            <option value="canceled">Canceled</option>
          </select>
        </div>
        <div>
          <label class="block text-gray-700">Search by Order ID</label>
          <input 
            v-model="searchQuery" 
            @input="applyFilters" 
            type="text" 
            placeholder="Enter Order ID" 
            class="w-full mt-1 p-2 border rounded" 
          />
        </div>
      </div>
    </div>

    <!-- Orders Table -->
    <div class="bg-white rounded-lg shadow p-5">
      <h2 class="font-semibold text-lg mb-4">Recent Orders</h2>
      <div class="overflow-x-auto">
        <table class="w-full table-auto">
          <thead>
            <tr class="bg-gray-200 text-left">
              <th class="px-4 py-2">Order ID</th>
              <th class="px-4 py-2">Amount</th>
              <th class="px-4 py-2">Status</th>
              <th class="px-4 py-2">Date</th>
              <th class="px-4 py-2">Customer Email</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in filteredOrders" :key="order.id" class="border-b hover:bg-gray-100 cursor-pointer" @click="goToOrderDetail(order.id)">
              <td class="px-4 py-2">#{{ order.id }}</td>
              <td class="px-4 py-2">${{ order.amount }}</td>
              <td class="px-4 py-2">{{ order.status }}</td>
              <td class="px-4 py-2">{{ new Date(order.created_at).toLocaleDateString() }}</td>
              <td class="px-4 py-2">{{ order.email }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter();
const totalOrders = ref(0);
const totalRevenue = ref(0);
const pendingOrders = ref(0);
const recentOrders = ref([]);
const filteredOrders = ref([]);
const selectedStatus = ref('');
const searchQuery = ref('');

// Fetch dashboard data when the component is mounted
onMounted(async () => {
  try {
    const response = await axios.get('http://localhost/api/admin/dashboard-data');
    totalOrders.value = response.data.totalOrders;
    totalRevenue.value = response.data.totalRevenue;
    pendingOrders.value = response.data.pendingOrders;
    recentOrders.value = response.data.recentOrders;
    filteredOrders.value = recentOrders.value; // Initialize with all orders
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
  }
});

// Function to apply filters
const applyFilters = () => {
  let filtered = recentOrders.value;

  if (selectedStatus.value) {
    filtered = filtered.filter(order => order.status === selectedStatus.value);
  }

  if (searchQuery.value) {
    filtered = filtered.filter(order => order.id.toString().includes(searchQuery.value));
  }

  filteredOrders.value = filtered;
};

// Navigate to the order detail view
const goToOrderDetail = (orderId) => {
  router.push(`/admin/orders/${orderId}`);
};
</script>
