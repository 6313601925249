<template>
  <div class="min-h-screen flex flex-col justify-start items-center pt-20">
    <div class="w-full max-w-md px-4 py-10 bg-white shadow-md rounded-lg">
      <h1 class="text-2xl font-bold text-center">Login</h1>
      <form @submit.prevent="submitForm" class="space-y-6 mt-6">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700"
            >Email</label
          >
          <input
            type="email"
            id="email"
            v-model="email"
            required
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700"
            >Password</label
          >
          <input
            type="password"
            id="password"
            v-model="password"
            required
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <button
          type="submit"
          class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Login
        </button>
        <p class="text-sm text-center mt-4">
          Don't have an account?
          <router-link to="/signup" class="text-blue-500 hover:text-blue-700"
            >Create one</router-link
          >
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/authStore"; // Ensure the path is correct
import { useRouter } from "vue-router";
import { ref } from "vue";
import { post } from "@/api/axoisInstance";

export default {
  name: "LoginView",
  setup() {
    const authStore = useAuthStore();
    const router = useRouter(); // Get access to the router instance
    const email = ref("");
    const password = ref("");

    const submitForm = () => {
      post("/login", {
        email: email.value,
        password: password.value,
      })
        .then((response) => {
          console.log("Login successful:", response.data);
          authStore.login(response.data.token); // Use the login action from your store
          router.push("/dashboard"); // Use router instance from useRouter
        })
        .catch((error) => {
          console.error("Login failed:", error.response.data);
          email.value = "";
          password.value = "";
          alert(
            "Unable to login. Please check your credentials and try again."
          );
        });
    };

    return { email, password, submitForm };
  },
};
</script>
