<template>
  <div class="min-h-screen bg-creme">

    <!-- Hero Section -->
    <div class="flex flex-col justify-start items-start text-left min-h-screen bg-[url('../bg.png')] bg-cover bg-center md:bg-center bg-left pt-5 pl-5 md:pl-24">
      <div class="p-6 md:p-10 rounded-lg max-w-lg">
        <h2 class="text-2xl md:text-5xl font-bold mb-4 text-white">Capture the Magic of Christmas</h2>
        <p class="mb-4 text-base md:text-2xl text-white">Preserve your holiday memories with personalized ornaments featuring a QR code that unlocks your special moments.</p>
        <div class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <router-link to="/shop" class="inline-block bg-creme hover:bg-dark-green hover:text-white text-dark-green font-bold py-3 px-6 rounded-full text-center">Shop Now</router-link>
          <router-link to="/howitworks" class="inline-block bg-creme hover:bg-pale-red hover:text-white text-pale-red font-bold py-3 px-6 rounded-full text-center">How It Works</router-link>
        </div>
      </div>
    </div>

    <!-- Categories Section -->
    <div class="pb-20 bg-creme text-center">

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 max-w-7xl mx-auto">
        <div class="border rounded-lg shadow-lg p-6 bg-red-100">
          <img src="https://via.placeholder.com/300x200" alt="Christmas Ornaments" class="w-full h-40 object-cover mb-4 rounded">
          <h4 class="text-xl font-semibold text-red-700 mb-2">Christmas</h4>
          <p class="text-red-600">$19.99</p>
        </div>
        <div class="border rounded-lg shadow-lg p-6 bg-green-100">
          <img src="https://via.placeholder.com/300x200" alt="Personalized Ornaments" class="w-full h-40 object-cover mb-4 rounded">
          <h4 class="text-xl font-semibold text-green-700 mb-2">Personalized</h4>
          <p class="text-green-600">$24.99</p>
        </div>
        <div class="border rounded-lg shadow-lg p-6 bg-blue-100">
          <img src="https://via.placeholder.com/300x200" alt="Memorial Ornaments" class="w-full h-40 object-cover mb-4 rounded">
          <h4 class="text-xl font-semibold text-blue-700 mb-2">Memorial</h4>
          <p class="text-blue-600">$29.99</p>
        </div>
        <div class="border rounded-lg shadow-lg p-6 bg-yellow-100">
          <img src="https://via.placeholder.com/300x200" alt="Special Edition Ornaments" class="w-full h-40 object-cover mb-4 rounded">
          <h4 class="text-xl font-semibold text-yellow-700 mb-2">Special Edition</h4>
          <p class="text-yellow-600">$34.99</p>
        </div>
      </div>
    </div>

    <!-- Call to Action Section -->
    <div class="py-20 bg-dark-green text-center">
      <h3 class="text-xl md:text-3xl font-bold text-white mb-6">Start a New Tradition Today</h3>
      <router-link to="/shop" class="inline-block bg-red-600 hover:bg-red-800 text-white font-bold py-3 px-6 rounded-full">Shop Now</router-link>
    </div>

  </div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>
