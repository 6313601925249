// tools/api.js
import axios from "axios";

let baseUrl = "http://127.0.0.1/";
let version = "api";

if (process.env.NODE_ENV == "production") {
  baseUrl = "https://api.mychristmasmemory.com/";
}
const axiosInstance = axios.create({
  baseURL: baseUrl + version,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("authToken")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "authToken"
      )}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log("handle Logout logic");

      // Example: Clear token and redirect to login page
      localStorage.removeItem("authToken");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const get = (url, params = {}) => axiosInstance.get(url, { params });
const post = (url, data, headers) => axiosInstance.post(url, data, headers);
const put = (url, data) => axiosInstance.put(url, data);
const remove = (url) => axiosInstance.delete(url);

export { get, post, put, remove };
