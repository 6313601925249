import { reactive } from 'vue';

const cart = reactive({
  items: JSON.parse(localStorage.getItem('cart')) || [],
  
  get itemCount() {
    return this.items.reduce((total, item) => total + item.quantity, 0);
  },

  addItem(product) {
    const existingItem = this.items.find(item => item.id === product.id);
    
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      this.items.push({ ...product, quantity: 1 });
    }
    
    this.saveCart();
  },

  removeItem(productId) {
    const index = this.items.findIndex(item => item.id === productId);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
    
    this.saveCart();
  },

  saveCart() {
    localStorage.setItem('cart', JSON.stringify(this.items));
  },

  clearCart() {
    this.items = [];
    this.saveCart();
  }
});

export default cart;
