<template>
  <VApp>
    <div class="min-h-screen flex flex-col bg-creme overflow-x-hidden">
      <!-- Navigation Bar -->
      <header class="border-b-2 border-pale-red mb-1 bg-creme text-dark-green font-cinzel text-xl font-bold">
        <!-- For Desktop -->
        <div class="hidden md:flex">
          <div class="container mx-auto px-4 py-5 flex justify-between items-center">
            <!-- Logo -->
            <img src="./assets/logo.png" alt="memories" class="h-10">

            <!-- Navigation Links -->
            <nav class="space-x-4 flex items-center">
              <template v-if="authStore.isLoggedIn">
                <router-link to="/dashboard" class="hover:text-gray-950">Dashboard</router-link>
                <a @click.prevent="logout" class="hover:text-gray-950 cursor-pointer">Logout</a>
              </template>
              <template v-else>
                <router-link v-for="link in links" :key="link.text" :to="link.path" class="hover:text-gray-950">
                  {{ link.text }}
                </router-link>
              </template>
            </nav>

            <!-- Cart Icon -->
            <div class="relative">
              <router-link to="/cart" class="text-xl hover:text-gray-950">
                🛒
              </router-link>
              <span v-if="cart.itemCount > 0" class="absolute -top-2 -right-2 bg-red-600 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                {{ cart.itemCount }}
              </span>
            </div>
          </div>
        </div>

        <!-- For Mobile -->
        <div class="md:hidden">
          <div class="container mx-auto px-4 py-5 flex justify-between items-center">
            <button @click="toggleMenu" class="text-2xl">☰</button>
            <img src="./assets/logo.png" alt="memories" class="h-10">
            <div class="relative w-[50px]">
              <router-link to="/cart" class="text-xl hover:text-gray-950">
                🛒
              </router-link>
              <span v-if="cart.itemCount > 0" class="absolute -top-2 -right-2 bg-red-600 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                {{ cart.itemCount }}
              </span>
            </div>
          </div>
        </div>

        <!-- Mobile Dropdown Menu -->
        <nav v-if="isMenuOpen" class="flex flex-col items-center bg-gray-900 text-white md:hidden">
          <template v-if="authStore.isLoggedIn">
            <router-link @click="toggleMenu" to="/dashboard" class="py-2 hover:text-gray-300 w-full text-center">
              Dashboard
            </router-link>
            <a @click.prevent="logout" class="py-2 hover:text-gray-300 w-full text-center cursor-pointer">
              Logout
            </a>
          </template>
          <template v-else>
            <router-link @click="toggleMenu" v-for="link in links" :key="link.text" :to="link.path" class="py-2 hover:text-gray-300 w-full text-center">
              {{ link.text }}
            </router-link>
          </template>
        </nav>
      </header>

      <VMain class="flex-grow">
        <!-- Router View -->
        <router-view></router-view>
      </VMain>

      <!-- Footer Section -->
      <footer class="bg-creme text-dark-green p-6">
        <div class="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <div class="mb-4 md:mb-0 text-center md:text-left">
            <p>&copy; 2024 Ornament Store. All Rights Reserved.</p>
          </div>
          <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 text-center">
            <a href="#" class="hover:text-yellow-300">Terms of Service</a>
            <a href="#" class="hover:text-yellow-300">Privacy Policy</a>
            <a href="#" class="hover:text-yellow-300">Newsletter Signup</a>
          </div>
        </div>
      </footer>
    </div>
  </VApp>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useAuthStore } from "./stores/authStore";
import cart from './stores/cartStore'; // Import the reactive cart

export default {
  name: "App",
  setup() {
    const authStore = useAuthStore();
    const isMenuOpen = ref(false);

    const links = computed(() => {
      return [
        { text: "Home", path: "/" },
        { text: "Shop", path: "/shop" },
        { text: "About", path: "/about" },
        { text: "FAQ", path: "/faq" },
      ];
    });

    function toggleMenu() {
      isMenuOpen.value = !isMenuOpen.value;
    }

    function logout() {
      console.log('Logging out');
      authStore.logout();
    }

    onMounted(() => {
      // Additional logic if needed after component mounts
    });

    return { authStore, isMenuOpen, toggleMenu, links, cart, logout };
  },
};
</script>
